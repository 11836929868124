import {
  getDatabase,
  ref as dbRef,
  set as dbSet,
  onValue,
} from "firebase/database";

import { state } from "./store";

const db = getDatabase();
export const albumsRef = dbRef(db, "albums/");

export const getInitialAlbums = async () => {
  return new Promise((resolve, reject) => {
    onValue(albumsRef, (snapshot) => {
      const data = snapshot.val();
      state.albums = data;
      return resolve(data);
    });
  });
};
