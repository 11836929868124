<template>
    <ion-grid>
        <ion-row>
            <ion-col
                v-for="(image, imageId) in images"
                :key="imageId"
                :size="12"
                :size-xs="12"
                :size-sm="6"
                :size-md="4"
                :size-lg="3"
                :size-xl="2"
            >
                <ion-card>
                    <!-- <ion-img @click="openModal" :src="image.thumbUrl"></ion-img> -->
                    <ion-img :src="image.thumbUrl"></ion-img>
                    <ion-segment @ion-change="(e) => updateImagePublication(e, albumId, imageId)"  :value="image.isHidden ? 'hidden' : 'public'">
                        <ion-segment-button value="public">
                        <!-- {{image.isHidden ? 'hidden' : 'public'}} -->
                            <ion-icon class="segment-icon-padding" :icon="eyeOutline" ></ion-icon>
                        </ion-segment-button>
                        <ion-segment-button value="hidden">
                            <ion-icon class="segment-icon-padding" :icon="eyeOffOutline" ></ion-icon>
                        </ion-segment-button>
                    </ion-segment>
                </ion-card>
            </ion-col>
        </ion-row>
    </ion-grid>
</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";
import ImageModal from "../components/ImageModal.vue";
import {
    IonGrid,
    IonCol,
    IonRow,
    IonCard,
    IonImg,
    modalController,
    IonSegment,
    IonSegmentButton,
    IonIcon,
} from "@ionic/vue";

import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { getDatabase, ref as dbRef, onValue, set as dbSet, set, } from "firebase/database";


const props = defineProps({
    albumId: {
        type: String,
        required: true,
    },
});

const images = ref(null);

const updateImagePublication = async (e, albumId, imageId) => {
    const db = getDatabase();
    const imageRef = dbRef(db, `albums/${albumId}/images/${imageId}/isHidden`);
    await set(imageRef, e.detail.value !== 'public');
}

onMounted(async () => {
    const db = getDatabase();
    const albumRef = dbRef(db, `albums/${props.albumId}/images/`);

    onValue(albumRef, (snapshot) => {
        const data = snapshot.val();    
        images.value = data;
    });
});

const openModal = async () => {
    const modal = await modalController.create({
        component: ImageModal,
    });
    modal.present();

    const { data, role } = await modal.onWillDismiss();

    if (role === "confirm") {
        this.message = `Hello!`;
    }
};
</script>

<style scoped>
.segment-icon-padding {
    padding-top: 8px;
    padding-bottom: 8px;
}
</style>
