<template>
    <ion-list>
        <ion-reorder-group @ionItemReorder="reorderAlbums" v-if="reorderEnabled" :disabled="!isAdmin">
            <ion-item
                v-for="album in sortedAlbums"
                :key="album.id"
                :data-id="album.id"
                :router-link="{
                    name: 'Album',
                    params: {
                        slug: album.slug,
                    },
                }"
            >
                <ion-label>{{ album.name }}</ion-label>
                <ion-icon v-if="album.isHidden" :icon="eyeOffOutline"></ion-icon>
                <ion-icon v-else :icon="eyeOutline"></ion-icon>
                <ion-reorder slot="start" />
            </ion-item>
        </ion-reorder-group>
    </ion-list>
</template>

<script setup>
import { set, getDatabase, ref as dbRef } from "@firebase/database";
import { IonItem, IonLabel, IonIcon, IonReorder, IonReorderGroup, IonList } from "@ionic/vue";
import { eyeOffOutline, eyeOutline } from "ionicons/icons";
import { ref, computed } from "vue";
import { state, isAdmin } from "../store";
const _ = require("lodash");

const reorderEnabled = ref(true);

const sortedAlbums = computed(() => {
    return _.sortBy(state.albums, ["sortOrder"]);
});

const db = getDatabase();
const reorderAlbums = (event) => {
    event.detail.complete();
    let index = 0;
    for (const child of event.target.children) {
        set(dbRef(db, "albums/" + child.dataset.id + "/sortOrder"), index);
        index++;
    }
};
</script>
