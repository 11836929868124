<template>
  <ion-toast
    style="color: red"
    v-for="error in errors"
    :key="error.code"
    :message="error.message"
    :duration="2000"
  >
  </ion-toast>
  <ion-card>
    <ion-card-header>
      <ion-card-title>Inloggen</ion-card-title>
      <ion-card-subtitle>Log in om foto's te uploaden</ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <form @submit="submitLogin">
        <ion-list lines="full" class="ion-no-margin">
          <ion-item>
            <ion-label position="floating">Email</ion-label>
            <ion-input required v-model="email" type="email"></ion-input>
          </ion-item>
          <ion-item>
            <ion-label position="floating">Wachtwoord</ion-label>
            <ion-input required v-model="password" type="password"></ion-input>
          </ion-item>
        </ion-list>
        <ion-button class="ion-margin-top" expand="block" type="submit">
          Inloggen
        </ion-button>
      </form>
    </ion-card-content>
  </ion-card>
  <br />
</template>

<script setup>
import { ref } from "vue";
import { signInWithEmailAndPassword } from "firebase/auth";

import { auth } from "@/firebase";
import { state } from "../store";

import {
  IonList,
  IonLabel,
  IonInput,
  IonItem,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonToast,
  useIonRouter,
} from "@ionic/vue";
import { getUserData } from "../store";

const email = ref();
const password = ref("");
const errors = ref([]);

const ionRouter = useIonRouter();

const submitLogin = async (e) => {
  e.preventDefault();
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email.value,
      password.value
    );
    state.user = userCredential.user;
    state.userRole === 'user'
    await getUserData();
    ionRouter.push({ name: "Albums" }).catch(() => { });
  } catch (error) {
    errors.value = [];
    switch (error.code) {
      case "auth/missing-email":
        errors.value.push({
          message: "Ben je vergeten je emailadres in te vullen?",
          code: error.code,
        });
        break;
      case "auth/invalid-email":
        errors.value.push({
          message:
            "Het lijkt er op dat je geen geldig mailadres hebt ingevoerd. Kijk je het nog even na?",
          code: error.code,
        });
        break;
      case "auth/internal-error":
        errors.value.push({
          message:
            "Oeps, er ging is mis. Kan je je gegevens nog eens controleren?",
          code: error.code,
        });
        break;
      case "auth/user-not-found":
        errors.value.push({
          message:
            "Deze gebruiker werd niet gevonden. Kan je je gegevens nog eens controleren?",
          code: error.code,
        });
        break;
      case "auth/wrong-password":
        errors.value.push({
          message:
            "Oeps, je wachtwoord lijkt niet te kloppen. Kijk je het nog even na?",
          code: error.code,
        });
        break;
      case "auth/too-many-requests":
        errors.value.push({
          message:
            "Je probeerde te vaak in te loggen. Probeer het later nog eens.",
          code: error.code,
        });
        break;

      default:
        break;
    }
  }
};
</script>
