<template>
    <input
        hidden
        ref="fileInput"
        @change="() => handleFileInputChange(albumId)"
        type="file"
        multiple
        accept="image/*"
    />

    <ion-fab
        vertical="bottom"
        horizontal="end"
        slot="fixed"
        class="ion-margin-bottom ion-margin-end"
    >
        <ion-fab-button @click="openFileInput">
            <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
    </ion-fab>

    <loader-component :message="progressText" />
</template>

<script setup>
import { ref as vueRef, defineProps, nextTick } from "vue";
import { v4 as uuidv4 } from "uuid";
import Compressor from "compressorjs";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { getDatabase, ref as dbRef, set } from "@firebase/database";
import { IonFab, IonFabButton, IonIcon, loadingController } from "@ionic/vue";
import { add } from "ionicons/icons";

import { storage } from "@/firebase.js";
import { state } from "../store";

import Image from "@/models/Image.js";
import { computed } from "@vue/reactivity";
import LoaderComponent from "./LoaderComponent.vue";

const db = getDatabase();

const props = defineProps({
    albumId: { type: String, required: true },
});

const fileInput = vueRef();

const numberOfImagesLeft = vueRef(0);
const numberOfImages = vueRef(0);
const numberOfImagesUploaded = vueRef(0);

const progressText = computed(() => {
    return `Foto ${numberOfImagesUploaded.value + 1} van de ${
        numberOfImages.value
    } aan het uploaden...`;
});

const openFileInput = () => fileInput.value.click();

const compressFile = async (file, isThumbnail = false) => {
    return new Promise((resolve, reject) => {
        new Compressor(file, {
            mimeType: "image/jpeg",
            convertSize: 0,

            quality: isThumbnail ? 0.8 : 0.8,
            maxWidth: isThumbnail ? 400 : 1400,
            maxHeight: isThumbnail ? 400 : 1400,
            success(result) {
                resolve(result);
            },
            error(error) {
                reject(error);
            },
        });
    });
};

const handleFileInputChange = async (albumId) => {
    const fileList = fileInput.value.files;

    const files = Array.from(fileList);
    numberOfImages.value = files.length;
    numberOfImagesLeft.value = files.length;

    const loading = await loadingController.create({
        message: progressText.value,
        spinner: "circles",
    });
    await loading.present();

    for (const file of files) {
        // generate a unique id
        const id = uuidv4();

        // compress both the image and a thumbnail
        const compressedFile = await compressFile(file);
        const thumbnail = await compressFile(compressedFile, true);

        // upload the files and store get the url
        const fileUrl = await uploadFile(compressedFile, id, albumId, false);
        const thumbnailUrl = await uploadFile(thumbnail, id, albumId, true);

        // store a reference to the image in the database
        const imageRef = dbRef(db, `albums/${albumId}/images/${id}`);
        await set(imageRef, {
            imageUrl: fileUrl,
            thumbUrl: thumbnailUrl,
            uploadedAt: Date.now(),
            uploadedBy: state.user.email,
            isHidden: true
        });

        numberOfImagesUploaded.value =
            numberOfImagesUploaded.value + 1 === numberOfImages.value
                ? numberOfImagesUploaded.value
                : numberOfImagesUploaded.value + 1;
        numberOfImagesLeft.value = numberOfImagesLeft.value - 1;

        loading.message = progressText.value;
    }

    await loading.dismiss();
    numberOfImages.value = 0;
    numberOfImagesLeft.value = 0;
    numberOfImagesUploaded.value = 0;
};

const uploadFile = async (file, id, albumId, isThumbnail) => {
    const storageRef = isThumbnail
        ? ref(storage, `${albumId}/${id}/thumbnail.jpg`)
        : ref(storage, `${albumId}/${id}/image.jpg`);

    const uploadTask = uploadBytesResumable(storageRef, file, {
        contentType: "image/jpeg",
        cacheControl: "public,max-age=31536000",
    });

    return new Promise((resolve, reject) => {
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                switch (snapshot.state) {
                    case "paused":
                        break;
                    case "running":
                        break;
                }
            },
            (error) => {
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                    case "storage/unauthorized":
                        break;
                    case "storage/canceled":
                        break;
                    case "storage/unknown":
                        break;
                }
                reject(error);
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve(downloadURL);
                });
            }
        );
    });
    // Listen for state changes, errors, and completion of the upload.
};
</script>
