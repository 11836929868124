<template>
  <ion-fab
  v-if="state.userRole === 'admin'"
    vertical="bottom"
    horizontal="end"
    slot="fixed"
    class="ion-margin-bottom ion-margin-end"
  >
    <ion-fab-button @click="openModal">
      <ion-icon :icon="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <ion-modal ref="modal" trigger="open-modal" :is-open="isOpen">
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal">Annuleren</ion-button>
        </ion-buttons>
        <ion-title>Nieuw album</ion-title>
        <ion-buttons slot="end">
          <ion-button :strong="true" @click="createNewAlbum">
            Opslaan
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <ion-item>
        <ion-label position="stacked">Naam van het album</ion-label>
        <ion-input
          v-model="newAlbumName"
          ref="input"
          type="text"
          placeholder=""
        ></ion-input>
      </ion-item>
    </ion-content>
  </ion-modal>
</template>

<script setup>
import {
  IonFab,
  IonFabButton,
  IonIcon,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonInput,
  IonLabel,
  IonItem,
} from "@ionic/vue";
import { add } from "ionicons/icons";
import { onMounted, ref as vueRef } from "vue";
import { uuidv4 } from "@firebase/util";
import {
  getDatabase,
  ref as dbRef,
  set as dbSet,
  onValue,
} from "firebase/database";

import { state } from "../store";
const slugify = require("slugify");

const isOpen = vueRef(false);
const openModal = () => (isOpen.value = true);
const closeModal = () => (isOpen.value = false);

const db = getDatabase();

const newAlbumName = vueRef("");
const createNewAlbum = async () => {
  const id = uuidv4();

  // TODO check if an album with this name already exists
  // TODO check slugs

  try {
    dbSet(dbRef(db, `albums/${id}`), {
      id,
      name: newAlbumName.value,
      createdBy: state.user.uid,
      isHidden: true,
      slug: slugify(newAlbumName.value, { lower: true }),
    });
    closeModal();
  } catch (error) {
    console.log(error);
  }
};

// todo: move elsewhere, also see AlbumList.vue AlbumPage.vue
const fetchAlbums = () => {
  const albumsRef = dbRef(db, "albums/");
  onValue(albumsRef, (snapshot) => {
    const data = snapshot.val();
    state.albums = data;
  });
};

onMounted(() => {
  fetchAlbums();
});
</script>
