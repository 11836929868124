<template>
    <ion-menu side="start" menu-id="custom" content-id="main">
        <ion-header>
            <ion-toolbar color="primary">
                <ion-title>Menu</ion-title>
            </ion-toolbar>
        </ion-header>
        <ion-content>
            <ion-list>
                <ion-item router-link="/albums">
                    <ion-label>Albums</ion-label>
                    <ion-icon :icon="albumsOutline"></ion-icon>
                </ion-item>
                <ion-item v-if="isAdmin" router-link="/gebruikers">
                    <ion-label>Gebruikers</ion-label>
                    <ion-icon :icon="peopleOutline"></ion-icon>
                </ion-item>
                <ion-item @click="logout">
                    <ion-label>Uitloggen</ion-label>
                    <ion-icon :icon="logOutOutline"></ion-icon>
                </ion-item>
            </ion-list>
        </ion-content>
    </ion-menu>
    <ion-router-outlet id="main"></ion-router-outlet>
</template>

<script setup>
import {
    IonContent,
    IonHeader,
    IonItem,
    IonList,
    IonMenu,
    IonRouterOutlet,
    IonTitle,
    IonToolbar,
    menuController,
    IonIcon,
    IonLabel,
    useIonRouter,
} from "@ionic/vue";
import { auth } from "../firebase";
import { state, isAdmin } from "../store";
import { logOutOutline, peopleOutline, albumsOutline } from "ionicons/icons";


const router = useIonRouter()

const openFirst = () => {
    menuController.enable(true, "first");
    menuController.open("first");
};

const openEnd = () => {
    menuController.open("end");
};

const openCustom = () => {
    menuController.enable(true, "custom");
    menuController.open("custom");
};

const logout = async () => {
    try {
        await auth.signOut();
        state.user = auth.currentUser;
        router.push({name: 'Login'});
    } catch (e) {
        console.log(e);
    }
};
</script>
