import { createRouter, createWebHistory } from "@ionic/vue-router";
import AlbumsPage from "../views/AlbumsPage.vue";
import UserPage from "../views/UserPage.vue";
import LoginPage from "../views/LoginPage.vue";
import AlbumPage from "../views/AlbumPage.vue";
import { menuController } from "@ionic/vue";


import { auth } from "../firebase";

const routes = [
  {
    path: "/",
    name: "Login",
    component: LoginPage,
  },
  // {
  //   path: "/home",
  //   name: "Home",
  //   component: HomePage,
  // },
  {
    path: "/albums",
    name: "Albums",
    component: AlbumsPage,
  },
  {
    path: "/albums/:slug",
    name: "Album",
    component: AlbumPage,
  },
  {
    path: "/gebruikers",
    name: "Users",
    component: UserPage,
  },
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from) => {
  if (auth.currentUser === null && to.name !== "Login") {
    return { name: "Login" }
  }
  // if (auth.currentUser && to.name === "Login") {
  //   return { name: "Albums" };
  // }

});

router.afterEach(async () => {
  await menuController.close();
});

export default router;
