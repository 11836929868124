<template>
    <ion-header>
        <ion-toolbar>
            <ion-buttons slot="start">
                <ion-button color="medium" @click="cancel">Sluiten</ion-button>
            </ion-buttons>
            <ion-title>Foto beheren</ion-title>
        </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
        <ion-item>
            <ion-label></ion-label>
        </ion-item>
    </ion-content>
</template>

<script>
import {
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonItem,
    IonLabel,
    modalController,
    IonSegment,
    IonSegmentButton,
    IonIcon,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
    name: "ImageModal",
    components: {
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonItem,
        IonLabel,
    },
    methods: {
        cancel() {
            return modalController.dismiss(null, "cancel");
        },
        confirm() {
            return modalController.dismiss(this.name, "confirm");
        },
    },
});
</script>
