import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";


const app = initializeApp({
  apiKey: "AIzaSyCrhRbRgjxbo8s8ZplFlPua8I7GlgSY8kQ",
  authDomain: "fotoapp-chirokaulille.firebaseapp.com",
  projectId: "fotoapp-chirokaulille",
  storageBucket: "fotoapp-chirokaulille.appspot.com",
  messagingSenderId: "20279874454",
  appId: "1:20279874454:web:3437b398c002f2837d03b7",
  databaseURL: "https://fotoapp-chirokaulille-default-rtdb.europe-west1.firebasedatabase.app"
});

export const storage = getStorage(app);
export const rootStorageRef = ref(storage);

export const auth = getAuth(app);
