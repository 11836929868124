<template>
    <ion-page>
        <header-component title="Gebruikers"  />
        <ion-content>
            <page-container>
                <!-- <ion-card>
                    <ion-card-header>
                        <ion-card-title>Gebruiker aanmaken</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        <form @submit="submitUserCreate">
                            <ion-list lines="full" class="ion-no-margin">
                                <ion-item>
                                    <ion-label position="floating">Email</ion-label>
                                    <ion-input required v-model="email" type="email"></ion-input>
                                </ion-item>
                                <ion-item>
                                    <ion-label position="floating">Gebruikersnaam</ion-label>
                                    <ion-input required v-model="displayName" type="text"></ion-input>
                                </ion-item>
                                <ion-item>
                                    <ion-label position="floating">Wachtwoord</ion-label>
                                    <ion-input required v-model="password" type="password"></ion-input>
                                </ion-item>
                            </ion-list>
                            <ion-button class="ion-margin-top" expand="block" type="submit">
                                Gebuiker toevoegen
                            </ion-button>
                        </form>
                    </ion-card-content>
                </ion-card> -->
                <ion-card>
                    <ion-card-header>
                        <ion-card-title>Overzicht</ion-card-title>
                    </ion-card-header>
                    <ion-card-content>
                        <ion-list>
                            <ion-item v-for="user in users" :key="user.uid">
                                <ion-label>
                                    {{ user.displayName !== "" ? user.displayName : user.email }}
                                    {{ user.role === "admin" ? " (admin)" : "" }}
                                </ion-label>
                            </ion-item>
                        </ion-list>
                    </ion-card-content>
                </ion-card></page-container
            >
        </ion-content>
    </ion-page>
</template>

<script setup>
import {
    IonContent,
    IonPage,
    IonCard,
    IonButton,
    IonCardContent,
    IonInput,
    IonList,
    IonItem,
    IonLabel,
    IonCardHeader,
    IonCardTitle,
} from "@ionic/vue";
import { onMounted, ref } from "vue";
import { alertCircleOutline } from "ionicons/icons";
import axios from "axios";

import { state } from "../store";

import HeaderComponent from "../components/HeaderComponent.vue";
import FooterComponent from "../components/FooterComponent.vue";
import PageContainer from "../components/PageContainer.vue";

const inviteEmail = ref();
const users = ref([]);
const BASE_URL = "https://us-central1-fotoapp-chirokaulille.cloudfunctions.net/api";

const getUsers = async () => {
    const token = state.user.accessToken;

    const response = await axios.get(`${BASE_URL}/users`, {
        headers: {
            Authorization: "Bearer " + token,
        },
    });

    users.value = response.data.users;
};

onMounted(() => {
    getUsers();
});
</script>
