import { reactive, computed } from "vue";
import { auth } from "./firebase";
import axios from "axios";

export const state = reactive({
    user: auth.currentUser,
    userRole: null,
    albums: [],
    currentAlbum: {},
});

export const isAdmin = computed(() => state.userRole === "admin");

const BASE_URL = "https://us-central1-fotoapp-chirokaulille.cloudfunctions.net/api";
export const getUserData = async () => {
    if (!state.user) return;
    if (!state.user.accessToken) return;

    const token = state.user.accessToken;

    const response = await axios.get(`${BASE_URL}/users/${state.user.uid}`, {
        headers: {
            Authorization: "Bearer " + token,
        },
    });
    state.userRole = response.data.user.role;
};

auth.onAuthStateChanged(async () => {
    getUserData();
});
