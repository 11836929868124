<template>
    <ion-page v-if="currentAlbum">
        <header-component :title="currentAlbum.name" />
        <ion-content>
            <ion-grid v-if="isAdmin && Object.keys(currentAlbum).length">
                <ion-row>
                    <ion-col :size="12" :size-md="4">
                        <ion-card>
                            <ion-card-header>
                                <ion-card-title>Instellingen</ion-card-title>
                            </ion-card-header>
                            <ion-card-content>
                                <ion-list lines="none">
                                    <ion-item>
                                        <ion-label>Openbaar</ion-label>
                                        <ion-toggle
                                            slot="start"
                                            :checked="!currentAlbum.isHidden"
                                            @ion-change="updateAlbumSetings"
                                        ></ion-toggle>
                                    </ion-item>
                                    <!-- <ion-item>
                    <ion-label>Uploads toestaan</ion-label>
                    <ion-toggle
                      slot="start"
                      name="allowUploads"
                      checked
                    ></ion-toggle>
                  </ion-item> -->
                                </ion-list>
                            </ion-card-content>
                        </ion-card>
                    </ion-col>
                </ion-row>
            </ion-grid>
            <template v-if="Object.keys(currentAlbum).length">
                <image-grid :album-id="currentAlbum.id" />
                <add-image :album-id="currentAlbum.id" />
            </template>
        </ion-content>
    </ion-page>
</template>

<script setup>
import {
    IonPage,
    IonContent,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonItem,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonToggle,
} from "@ionic/vue";

import HeaderComponent from "../components/HeaderComponent.vue";
import { useRouter } from "vue-router";
import AddImage from "../components/AddImage.vue";
import ImageGrid from "../components/ImageGrid.vue";
import { onBeforeMount, ref } from "vue";
import { state, isAdmin } from "../store";
import { getInitialAlbums } from "../database";
import { getDatabase, set, ref as dbRef } from "@firebase/database";

const router = useRouter();
const currentAlbumSlug = ref(router.currentRoute.value.params.slug);

const currentAlbum = ref(null);

onBeforeMount(() => {
    getInitialAlbums().then((albums) => {
        currentAlbum.value = Object.entries(albums).find(
            ([albumId, albumData]) => albumData.slug === currentAlbumSlug.value
        )[1];
    });
})



const updateAlbumSetings = async (e) => {
    const db = getDatabase();
    const albumRef = dbRef(db, `albums/${currentAlbum.value.id}/isHidden`);
    await set(albumRef, !e.detail.checked);
};
</script>
